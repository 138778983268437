<template>
	<a-result status="success" title="支付成功"
		:sub-title="`订单号: ${order_id}.`">
		<template #extra>
			<a-button key="console" @click="$go('/orderSystem/myOrder')" type="primary">
				查看订单
			</a-button>
			<a-button @click="$go('/home')" key="buy">
				再次购买
			</a-button>
		</template>
	</a-result>
</template>
<script>
	export default {
		data() {
			return {
				order_id: ''
			};
		},
		mounted() {
			let {
				order_id
			} = this.$route.query
			this.order_id = order_id
		}
	};
</script>
<style lang="scss">

</style>
